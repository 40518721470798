import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./Auth";

const AuthRoute = ({ component: Component, ...rest }) => {
  
  const currentUser = useAuth();
  
  return (
    <Route
      {...rest}
      render={routeProps =>
        currentUser ? (
          <Component { ...routeProps} />
        ) : (
          <Redirect to={"/"} />
        )
      }
    ></Route>
  );
};


export default AuthRoute