import React from 'react'
import { Divider, Typography, Link, AppBar} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
	appBar: {
	  top: 'auto',
	  bottom: 0,
	},
	link: {
		color: 'black',
	}
  }));
const Footer = () =>{

	const classes = useStyles();
    return (
        <>
            <AppBar className={classes.appBar}>
			<Divider></Divider>
            <Typography>Copyright 2019 <Link className={classes.link} href="http://www.phospec.io">Phospec Industries Inc</Link> All Rights Reserved.</Typography>
			</AppBar>
        </>
    );
}

export default Footer;
