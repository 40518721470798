import React from 'react';
import logo from './logo512.png';
import './App.css';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { CssBaseline,  Box } from '@material-ui/core';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { AuthProvider } from './common/Auth';
import AuthRoute from './common/AuthRoute';

import Home from './Home';
import SignIn from './SignIn';
import SignUp from './SignUp';
import Landing from './Landing';

const theme = createMuiTheme({
	palette: {
	  type: 'dark',
	  primary: {
		  main : '#880E4F',
	  },
	  secondary: {
		  main : '#0E8847',
  
	  },
	  background:{ 
		default : '#000',
	  }
	},
  });

function App() {
  return (
	<div className="App">
		<AuthProvider>
			<ThemeProvider theme={theme}>
			<CssBaseline />
			<Router>
				<Switch>
					<AuthRoute exact path="/app" component={Home} />
					<Route exact path="/login" component={SignIn} />
					<Route exact path="/signup" component={SignUp} />
					<Route path="/" component={Landing} />
				</Switch>
			</Router>
			</ThemeProvider>
		</AuthProvider>

	</div>
  );
}

export default App;
