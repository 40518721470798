import React, {useState} from 'react'
import TopBanner from './common/TopBanner';
import SignUpForm from './common/SignUpForm';
import icon from './icon512.svg';
import firebase, { firestore } from './common/firebase';

const SignUp = ( {history }) => {


	const [ values, setValues] = useState({});

	const handleChange = name => event => {
		setValues({ ...values, [name]: event.target.value });
	};
	const validateValues = () => {
		return (values.first && values.first.length > 1) 
			&& 
			(values.last && values.last.length > 1)
			&&
			(values.email && values.email.length > 3)
			&&
			(values.password && values.password.length > 0 && values.passwordconfirm && values.passwordconfirm.length)
			&&
			(values.passwordconfirm == values.password);
	}

	const apply = async (evt) => {
		
		if(validateValues()){
			var { password, passwordconfirm, ...rest} = values;
			firebase
				.auth()
				.createUserWithEmailAndPassword(values.email, values.password)
					.catch(function(error) {
						// Handle Errors here.
						var errorCode = error.code;
						var errorMessage = error.message;
						if (errorCode == 'auth/weak-password') {
							alert('The password is too weak.');
						} else {
							alert(errorMessage);
							alert(process.env.REACT_APP_API_KEY);
						}
						console.log(error);
					})
					.then( (result) =>{
						console.log(result);
						if(result && result.user){
							rest.stripe_user = "";
							rest.stripe_payment_method = "";
							rest.subscription_token = "";
							rest.subscription_active = false;
							rest.subscription_level = "";
							firestore.collection("users").doc(result.user.uid).set( rest )
								.then( () => {
									firebase.auth().signOut().then( () => {
										history.push("/login");
									}

									);
									
								}
							);
						}
					});
		} else {
			alert("Please check your entries and try again");
		}
			
	}
	return (
		<div>
			<TopBanner title="Sign up" history={history} icon={icon}></TopBanner>
			<div className='App-header'>
				<SignUpForm history={history} values={values} handleChange={handleChange} applyFunc={apply}></SignUpForm>
			</div>
		</div>
	);
}

export default SignUp;