import React from 'react';

import TopBanner from './common/TopBanner';
import SignInForm from './common/SignInForm';

import icon from './icon512.svg';

const SignIn = ({history}) => {
	return (
		<>
			<TopBanner title="Sign In" history={history} icon={icon}></TopBanner>
			<div className='App-header'>
			<SignInForm history={history}></SignInForm>
			</div>
		</>
	);
}

export default SignIn;