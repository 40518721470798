import React , { useState } from 'react';
import TopBanner from './common/TopBanner';
import Footer from './common/Footer';
import {Button} from '@material-ui/core';
import icon from './icon512.svg';

const Landing = () => {
	return (
		<div>
			<TopBanner icon={icon} title="Welcome to Swarm"></TopBanner>
			<div className='App-header'>
			<img src={icon} alt="Swarm Logo" width='200'></img>
			<br />
			<Button variant="contained" href="/login">Sign In</Button>
			<br></br>
			<Button variant="contained" href="/signup">Sign Up</Button>
			</div>
			<Footer></Footer>
		</div>
	);
}

export default Landing;