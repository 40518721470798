import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Home, AccountCircle } from '@material-ui/icons/';
import { Menu, MenuItem, IconButton, Typography, Toolbar, AppBar} from '@material-ui/core';

import firebase  from './firebase';
import { useAuth } from './Auth'


const useStyles = makeStyles(theme => ({
  root: {
	flexGrow: 1,
  },
  menuButton: {
	marginRight: theme.spacing(2),
  },
  title: {
	flexGrow: 1,
  },
}));

export default function TopNav({history, icon, homeClicked}) {
  const classes = useStyles();
  const currentUser  = useAuth();
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  
  const open = Boolean(anchorEl);

  const handleMenu = event => {
	setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
	setAnchorEl(null);
  };

  const doSignOut = (e) => {
	  e.preventDefault();
	  handleClose();
	  firebase.auth().signOut();
  }

  const goHome = (e) => {
	e.preventDefault();
	homeClicked();
  }

  return (
	<div className={classes.root}>
	  <AppBar position="fixed">
		<Toolbar>
		  
		   <img src={icon} height={40}></img>
		  
		  <Typography variant="h6" className={classes.title}>
	
		  </Typography>
		  <Typography fontSize={10} variant="h6" className={classes.menuButton}>
			{ currentUser && currentUser.email }
		  </Typography>
		  <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={goHome}>
			<Home />
		  </IconButton>
		  
		  <div>
			<IconButton
			  aria-label="account of current user"
			  aria-controls="menu-appbar"
			  aria-haspopup="true"
			  onClick={handleMenu}
			  color="inherit"
			>
			  <AccountCircle />
			</IconButton>
			<Menu
			  id="menu-appbar"
			  anchorEl={anchorEl}
			  anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			  }}
			  keepMounted
			  transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			  }}
			  open={open}
			  onClose={handleClose}
			>
			  <MenuItem onClick={handleClose}>Profile</MenuItem>
			  <MenuItem onClick={handleClose}>My account</MenuItem>
			  <MenuItem onClick={doSignOut}>Sign Out</MenuItem>
			</Menu>
		  </div>
		  
		</Toolbar>
	  </AppBar>
	</div>
  );
}