import React from 'react';
import {firestore, FieldValue}  from './common/firebase';
import TopNav from './common/TopNav';
import Footer from './common/Footer';
import { useAuth } from './common/Auth'
import { makeStyles } from '@material-ui/styles';

import  { AppBar,Button, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, ExpansionPanelActions , Typography} from '@material-ui/core'; 
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {useSwarms, useDevices } from './Context';
import icon from './icon512.svg';
import logo from './Logo.svg';
const useStyles = makeStyles(theme => ({
	root: {
	  width: '100%',
	},
	page: {
		backgroundColor: '#282c34',
  		minHeight: '100vh',
  		display: 'flex',
  		flexDirection: 'column',
  		alignItems: 'center',
  		//justifyContent: 'center',
  		fontSize: 'calc(10px + 2vmin)',
  		color: 'white',
	},

	heading: {
	  fontSize: 15,
	  fontWeight: 'regular',
	},
  }));

const DeviceListItem = ({device}) => {
	const classes = useStyles();
	
	return (
	<ExpansionPanel>
			<ExpansionPanelSummary
				expandIcon = {< ExpandMoreIcon />}
				aria-controls = "panel1a-content"
				id = "panel1a-header" >
				<img src={icon} width={20} alt="(S)" />
				<Typography className = {classes.heading} > {device.name} </Typography>
			</ExpansionPanelSummary> 
			<ExpansionPanelDetails>
				<Typography >Device id: {device.uid}</Typography>
			</ExpansionPanelDetails> 
	</ExpansionPanel> );
} 

const SwarmListItem = ({swarm}) => {
	
	const classes = useStyles();
	const currentUser = useAuth();
	const {devices ,loading, error} = useDevices(swarm.uid, 100);
	const addDevice = async(event) => {
		await firestore
			.doc( 'users/'+ currentUser.uid).collection("devices")
			.add({name: "New Device", swarm: swarm.uid , created: FieldValue.serverTimestamp()});
	}

	return(
		<>
		<ExpansionPanel>
			<ExpansionPanelSummary
				expandIcon = {< ExpandMoreIcon />}
				aria-controls = "panel1a-content"
				id = "panel1a-header" >
				<img src = {icon} width={20} alt="(S)" />
				<Typography className = {classes.heading} > {swarm.name} </Typography>
			</ExpansionPanelSummary> 
			<ExpansionPanelDetails>
				<Typography >Swarm id: {swarm.uid}</Typography>
				<Typography >Devices:</Typography>
				<div className={classes.root}>
				{ loading  ? (<p>Loading...</p>) : ( devices && devices.map( (device) => (
							<DeviceListItem key={device.uid} device={device} />
				)))} 
				{ error && <p>ERROR</p> }
				</div>
			</ExpansionPanelDetails> 
			<ExpansionPanelActions>
				<Button onClick={addDevice}>Add Device</Button>
			</ExpansionPanelActions>
		</ExpansionPanel>
		</>
	);
}

const Home = ({history}) => {

	const classes = useStyles();

	const currentUser = useAuth();
	const {swarms, loading, error}  = useSwarms(100);
	if(!currentUser){
		history.push("/")
	}

	const createSwarm = async (e) => {
		await firestore
			.doc( 'users/'+ currentUser.uid).collection("swarms")
			.add({name: "New Swarm", created: FieldValue.serverTimestamp()});

	}

	const goHome = () => {
		
	}
	return (
		<>
		<TopNav homeClicked={goHome} icon={logo}></TopNav>
		<div className={classes.page}>
			
			<br></br>
			<p>Your API key is: {currentUser.uid}</p>
			<Button variant="contained" onClick={createSwarm}> Create a new Swarm </Button>
			<div className={classes.root}>
			{
				loading &&
					(<p>"Loading...."</p> )
			}
			{
				swarms && swarms.map( (swarm) => (
					<SwarmListItem key={swarm.uid} swarm={swarm}></SwarmListItem>
				))
			}
			</div>
		</div>
		<Footer></Footer>
		</>
	);
}

export default Home;