import * as fire from 'firebase';

var firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER,
	appId: process.env.REACT_APP_APP_ID,
	measurementID: process.env.REACT_APP_MEASUREMENT_ID
  };
  // Initialize Firebase
const firebase = fire.initializeApp(firebaseConfig);
const firestore = firebase.firestore();

let FieldValue = fire.firestore.FieldValue;


export default firebase;
export  {firestore, FieldValue}; 