import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBack } from '@material-ui/icons/';
import { IconButton, Typography, Toolbar, AppBar} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
	root: {
	  flexGrow: 1,
	},
	menuButton: {
	  marginRight: theme.spacing(2),
	},
	title: {
	  flexGrow: 1,
	},
  }));

  function TopBanner({title, history, icon }) {
	
	const classes = useStyles();
	
	const goHome = (event) => {
		history.push("/landing")
	}

	return (
		<div className={classes.root}>
		<AppBar position="fixed">
			<Toolbar>
				<IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={goHome}>
					<ArrowBack />
				</IconButton>
				<Typography variant="h6" className={classes.title}>
					{title}
				</Typography>
				<img src={icon} alt="Icon" height={40}></img>
			</Toolbar>
		</AppBar>
		</div>	
	)
}

export default TopBanner

