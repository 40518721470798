import React, { useEffect, useState, useContext } from "react";
import firebase, {firestore} from "./firebase";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
	firebase.auth().onAuthStateChanged( authUser => {
		if (authUser) {
			firestore.collection("users")
			.doc(authUser.uid)
			.get()
			.then(snapshot => {

				const dbUser = snapshot.data();
				// default empty roles
				if(dbUser){
					if (!dbUser.roles) {
						dbUser.roles = [];
					}
					authUser = {
						uid: authUser.uid,
						email: authUser.email,
						emailVerified: authUser.emailVerified,
						providerData: authUser.providerData,
						...dbUser,
					};
				}
				setCurrentUser(authUser);
				
			});
			setCurrentUser(authUser);
		} else {
			setCurrentUser(undefined);
		}	
	});

  }, []);

  return (
    <AuthContext.Provider value={ {currentUser} } >
      {children}
    </AuthContext.Provider>
  );
};
// useAuth Hook!
export const useAuth = () => {
  const { currentUser } = useContext(AuthContext);
  return currentUser;
}