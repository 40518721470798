import React, {
    useEffect,
    useState,
    useContext
} from "react";

import {firestore} from "./common/firebase";
import { useAuth } from './common/Auth' 


// *** System API ***
/*
system = uid => this.db.doc(`systems/${uid}`);
systems = () => this.db.collection('systems');
usersystems = userid => this.systems().where( "userId", "==", `${userid}`);

gateway = uid => this.db.doc(`gateways/${uid}`);
gatewayconfig = uid => this.db.doc(`gateways/${uid}/config/network`);
gatewaystate = uid => this.db.doc(`gateways/${uid}/config/state`);

gateways = () => this.db.collection('gateways');
systemgateways = sysid => this.gateways().where("system_id", "==", `${sysid}`);

scene = uid => this.db.doc( `scenes/${uid}` );
scenes = () =>this.db.collection('scenes');
systemscenes = sysid =>this.scenes().where("system_id", "==", `${sysid}`);
*/

function useSwarms(limit) {
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [swarms, setSwarms] = useState([])
    const currentUser = useAuth();


    useEffect(
        () => {
            
            const unsubscribe = firestore
				.doc('users/' + currentUser.uid )
				.collection('swarms')
                .orderBy('name')
                .limit(limit)
                .onSnapshot(
                    snapshot => {
                    const sys = [];
                    snapshot.forEach(doc =>
                            sys.push({...doc.data(), uid: doc.id} ),
                        );
                        setSwarms(sys.reverse());
                        setLoading(false);
                    } ,
                    err => { 
                        setError(err);
                    }
                )
            return () => unsubscribe()
        },
        [currentUser.uid, limit]
    )

    return {
        error,
        loading,
        swarms,
    };
}

function useDevices(uid, limit) {
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [devices, setDevices] = useState([])
    const currentUser = useAuth();
    useEffect(
        () => {
            
            const unsubscribe = firestore
				.doc('users/' + currentUser.uid )
				.collection('devices')
                .where( "swarm", "==", uid )
                .limit(limit)
                .onSnapshot(
                    snapshot => {
                    const sys = [];
                    snapshot.forEach(doc =>
                            sys.push({...doc.data(), uid: doc.id} ),
                        );
                        setDevices(sys.reverse());
                        setLoading(false);
                    } ,
                    err => { 
                        setError(err);
                    }
                )
            return () => unsubscribe()
        },
        [uid , limit]
    )

    return {
        error,
        loading,
        devices,
    };
}
/*
function useGateways(uid, limit) {
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [gateways, setGateways] = useState([])
    
    useEffect(
        () => {
            
            const unsubscribe = firestore
                .collection('gateways')
                .where( "system_id", "==", uid )
                .orderBy('createdAt', 'desc')
                .limit(limit)
                .onSnapshot(
                    snapshot => {
                    const sys = [];
                    snapshot.forEach(doc =>
                            sys.push({...doc.data(), uid: doc.id} ),
                        );
                        setGateways(sys.reverse());
                        setLoading(false);
                    } ,
                    err => { 
                        setError(err);
                    }
                )
            return () => unsubscribe()
        },
        [uid, limit]
    )

    return {
        error,
        loading,
        gateways,
    };
}


const CurrentSystemContext = React.createContext();

const CurrentSystemProvider = ({ children }) => {
    const [ error, setError] = useState(false);
    const [ currentSystem,  setCurrentSystem ] = useState(undefined);
    const [loading, setLoading] = useState(true);
    
  const [ currentSystemUID, setCurrentSystemUID ] = useState("");

  useEffect(
    () =>{
    if( currentSystemUID !== ""){
        const unsubscribe = firestore
            .collection('systems')
            .doc(currentSystemUID)
            .onSnapshot(
                doc => {
                    setLoading(false)
                    setCurrentSystem({ ...doc.data(), uid: doc.id})
                },
                err => {
                    setError(err)
                }
            );
        return () => unsubscribe()
    } else {
        setCurrentSystem(undefined);
    }
},
[ currentSystemUID]);

  return (
    <CurrentSystemContext.Provider value={ { error, loading, currentSystem, setCurrentSystemUID } } >
      {children}
    </CurrentSystemContext.Provider>
  );
};

const useCurrentSystem = () => {
  const { error, loading, currentSystem, setCurrentSystemUID } = useContext(CurrentSystemContext);
  return { error, loading, currentSystem, setCurrentSystemUID } ;
}

export { CurrentSystemProvider, CurrentSystemContext, useSwarms, useScenes, useGateways, useCurrentSystem };
*/
export { useSwarms, useDevices };